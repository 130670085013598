import { ProdutoModule } from './../components/produto/produto.module';
import { ResolveModeloService } from './../components/modelo/resolve/resolve-modelo.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ResolveSegmentsService } from '../components/segments/resolve/resolve-segments.service';
import { ResolveUserService } from '../components/users/resolve/resolve-user.service';
import { ResolveTeamService } from '../components/teams/resolve/resolve-team.service';
import { ResolveProfileService } from '../components/profiles/resolve/resolve-profile.service';
import { ResolveActivityFieldService } from '../components/activity-field/resolve/resolve-activity-field.service';
import { ResolveHolidayService } from '../components/holidays/resolve/resolve-holiday.service';
import { ResolveRolesService } from '../components/roles/resolve/resolve-roles.service';
import { ResolveAttendanceService } from '../components/attendance/resolve/resolve-attendance.service';
import { ResolveBenefitsService } from '../components/benefits/resolve/resolve-benefits.service';
import { ResolveImpostService } from '../components/impost/resolve/resolve-impost.service';
import { ResolveDiscountService } from '../components/discount/resolve/resolve-discount.service';
import { ResolveCommissionService } from '../components/commission/resolve/resolve-commission.service';
import { ResolveVehiclesService } from '../components/vehicles/resolve/resolve-vehicles.service';
import { ResolveDistrictService } from '../components/district/resolve/resolve-district.service';
import { ResolveEmpresaService } from '../components/empresa/resolve/resolve-empresa.service';
import { ResolveEmployeeService } from '../components/employees/resolve/resolve-employee.service';
import { ResolveProdutoService } from '../components/produto/resolve/resolve-produto.service';
import { MarcaResolveService } from '../components/marca/resolve/marca-resolve.service';
import { ResolvePostsService} from '../components/posts/resolve/resolve-posts.service';
import { ResolveRotasService } from '../components/rotas/resolve/resolve-rotas.service';
import { LancamentoAgendaResolveService } from '../components/lancamento-agenda/resolve/lancamento-agenda-resolve.service';
import { AgendaResolveService } from '../components/agenda/resolve/agenda-resolve.service';
import { ResolveMotoboyService } from '../components/motoboy/resolve/resolve-motoboy.service';

const GlobalModuleRoutes: Routes = [
  {
    path: 'funcionarios',
    loadChildren: '../components/employees/employees.module#EmployeesModule',
    resolve: { rows: ResolveEmployeeService}
  },
  {
    path: 'usuarios',
    loadChildren: '../components/users/users.module#UsersModule',
    resolve: { rows: ResolveUserService }
  },
  {
    path: 'equipes',
    loadChildren: '../components/teams/teams.module#TeamsModule',
    resolve: { rows: ResolveTeamService }
  },
  {
    path : 'postos',
    loadChildren: '../components/posts/posts.module#PostsModule',
    resolve: { rows: ResolvePostsService}
  },
  {
    path : 'rotas',
    loadChildren: '../components/rotas/rotas.module#RotasModule',
    resolve: { rows: ResolveRotasService}
  },
  {
    path: 'atividade-comercial',
    loadChildren: '../components/segments/segments.module#SegmentsModule',
    resolve: { rows: ResolveSegmentsService }
  },
  {
    path: 'perfis',
    loadChildren: '../components/profiles/profiles.module#ProfilesModule',
    resolve: { rows: ResolveProfileService }
  },
  {
    path: 'areaatuacao',
    loadChildren: '../components/activity-field/activity-field.module#ActivityFieldModule',
    resolve: { rows: ResolveActivityFieldService }
  },
  {
    path: 'feriados',
    loadChildren: '../components/holidays/holidays.module#HolidaysModule',
    resolve: { rows: ResolveHolidayService }
  },
  {
    path: 'cargos',
    loadChildren: '../components/roles/roles.module#RolesModule',
    resolve: { rows: ResolveRolesService }
  },
  {
    path: 'frequencias',
    loadChildren: '../components/attendance/attendance.module#AttendanceModule',
    resolve: { rows: ResolveAttendanceService }
  },
  {
    path: 'beneficios',
    loadChildren: '../components/benefits/benefits.module#BenefitsModule',
    resolve: { rows: ResolveBenefitsService }
  },
  {
    path: 'impostos',
    loadChildren: '../components/impost/impost.module#ImpostModule',
    resolve: { rows: ResolveImpostService }
  },
  {
    path: 'descontos',
    loadChildren: '../components/discount/discount.module#DiscountModule',
    resolve: { rows: ResolveDiscountService }
  },
  {
    path: 'comissoes',
    loadChildren: '../components/commission/commission.module#CommissionModule',
    resolve: { rows: ResolveCommissionService }
  },
  {
    path: 'veiculos',
    loadChildren: '../components/vehicles/vehicles.module#VehiclesModule',
    resolve: { rows: ResolveVehiclesService }
  },
  {
    path: 'motoboy',
    loadChildren: '../components/motoboy/motoboy.module#MotoboyModule',
    resolve: { rows: ResolveMotoboyService }
  },
  {
    path: 'bairros',
    loadChildren: '../components/district/district.module#DistrictModule',
    resolve: { rows: ResolveDistrictService }
  },
  {
    path: 'empresas',
    loadChildren: '../components/empresa/empresa.module#EmpresaModule',
    resolve: { rows: ResolveEmpresaService  }
  },
  {
    path: 'produtos',
    loadChildren: '../components/produto/produto.module#ProdutoModule',
    resolve: { rows: ResolveProdutoService  }
  },
  {
    path: 'modelos',
    loadChildren: '../components/modelo/modelo.module#ModeloModule',
    resolve: { rows: ResolveModeloService  }
  },
  {
    path: 'marcas',
    loadChildren: '../components/marca/marca.module#MarcaModule',
    resolve: { rows: MarcaResolveService  }
  },
  {
    path: 'lancamento-agenda',
    loadChildren: '../components/lancamento-agenda/lancamento-agenda.module#LancamentoAgendaModule',
    resolve: { rows: LancamentoAgendaResolveService  }
  },
  {
    path: 'agenda',
    loadChildren: '../components/agenda/agenda.module#AgendaModule',
    resolve: { rows: AgendaResolveService  }
  },
];

@NgModule({
  imports: [RouterModule.forChild(GlobalModuleRoutes)],
  exports: [RouterModule],
  declarations: [],
  providers: [
    ResolveUserService,
    ResolveTeamService,
    ResolveRotasService,
    ResolvePostsService,
    ResolveSegmentsService,
    ResolveProfileService,
    ResolveActivityFieldService,
    ResolveHolidayService,
    ResolveRolesService,
    ResolveAttendanceService
  ]
})
export class GlobalRoutingModule { }
