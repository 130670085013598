import { take, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/observable/of';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { of } from 'rxjs';
import { MotoboyService } from '../services/motoboy.service';

@Injectable({
  providedIn: 'root'
})
export class ResolveMotoboyService implements Resolve<any> {
  
  constructor(
    private motoboyService: MotoboyService) { }

  resolve(): Observable<any> {
     return this.motoboyService.pageAll(10, 1).pipe(
       take(1),
       mergeMap(motoboy => {
        return of(motoboy['object']);
       })
     );
  }

}
