import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { SGF_API } from '../../../../../shared/api/sgf.api';
import { Motoboy } from 'app/main/shared/model/motoboy/motoboy.model';

@Injectable({
  providedIn: 'root'
})
export class MotoboyService {

  private host = `${SGF_API}` + 'global/api/public/motoboy';
  private posto: string;

  constructor(
    private httpClient: HttpClient) {}

  findAll(): any {
    return this.httpClient.get(`${this.host}?size=10000`)
      .pipe(
        catchError(this.handleError)
      );
  }

  pageAll(size: number, page: number): Observable<any>  {
   
    this.checkIfHasPosto();
  
    return this.httpClient.get(`${this.host}?size=${size}&page=${page}&${this.posto}&sort=-id`)
    .pipe(
      catchError(this.handleError)
    );

  }

  create(motoboy: any): any {
    const body = JSON.stringify(motoboy);
    return this.httpClient.post<Motoboy[]>(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  update(motoboy: any): any {
    const body = JSON.stringify(motoboy);
    return this.httpClient.put<Motoboy[]>(`${this.host}/`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  delete(id: any): any {
    return this.httpClient.delete<Motoboy[]>(`${this.host}/${id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  httpOptions(token?: string): any {
    return {headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.posto = user.idPosto ? 'postoId=' + user.idPosto : '';
    }
}

}
